import React, { FC, useEffect, useState } from "react";
import * as Yup from "yup";
import { InputText, InputSelect } from "../../../../components";
import { translate } from "../../../../languages";
import { Button, CreateAlert, InputWraper, useForm } from "../../../../modules";
import { AdminService } from "../../../../services/admin";
import "./index.scss";

export const EditUser: FC<{ data: any; onFinishEdit: () => void }> = (props) => {
  const {
    userName,
    email,
    firstName,
    // lastName, phoneNumber
    userRole,
  } = props.data;
  const [listRole, setListRole] = useState<Array<any>>([]);
  const [role, setRole] = useState<string>(userRole || "");

  useEffect(() => {
    AdminService.getListRole().then((res) =>
      setListRole(() => {
        return res.map((item: any) => {
          return { label: item === "SUPER_ADMIN" ? "SUPER ADMIN" : item, value: item };
        });
      })
    );
  }, []);

  const { handleSubmit, getInputProps } = useForm({
    structure: [
      {
        name: "userName",
        defaultValue: userName,
        isDisabled: true,
      },
      {
        name: "email",
        defaultValue: email,
        validate: Yup.string().required(translate("must-be-provided")),
        // isDisabled: true,
      },
      {
        name: "firstName",
        defaultValue: firstName,
        validate: Yup.string().required(translate("must-be-provided")),
      },
      // {
      //     name: "lastName",
      //     defaultValue: lastName,
      //     validate: Yup.string().required(translate("must-be-provided")),
      // },
      // {
      //     name: "phoneNumber",
      //     defaultValue: phoneNumber || "",
      //     validate: Yup.string().required(translate("must-be-provided")),
      // },
    ],
    // onSubmit: async (values) => {
    //     return AdminService.updateProfile({ ...values, phoneCountryId: 1 })
    //         .then(() => {
    //             CreateAlert({
    //                 message: "Please check email to verify your account.",
    //                 type: "success",
    //             });
    //             props.onFinishEdit();
    //         })
    //         .catch((error) => {
    //             CreateAlert({
    //                 message: error.message,
    //                 type: "danger",
    //             });
    //         });
    // },
    onSubmit: async (values) => {
      const { userId, countryId: phoneCountryId } = props.data;
      return AdminService.updateProfile({
        ...values,
        userRole: role,
        userId,
        phoneCountryId,
      })
        .then(() => {
          CreateAlert({
            message: "Update success.",
            type: "success",
          });
          props.onFinishEdit();
        })
        .catch((error) => {
          CreateAlert({
            message: translate(error.message),
            type: "danger",
          });
        });
    },
  });
  return (
    <form className="Edit" onSubmit={handleSubmit}>
      <InputWraper
        label={translate("Username")}
        inputProps={getInputProps("userName")}
        component={InputText}
      />
      <InputWraper
        label={translate("email-address")}
        inputProps={getInputProps("email")}
        component={InputText}
      />
      <div className="group">
        <InputWraper
          label={"Display name"}
          inputProps={getInputProps("firstName")}
          component={InputText}
          className="mr5"
        />
        {/* <InputWraper
                    label={translate("last-name")}
                    inputProps={getInputProps("lastName")}
                    component={InputText}
                    className="ml5"
                /> */}
      </div>

      {/* <InputWraper
                label={translate("phone-number")}
                inputProps={getInputProps("phoneNumber")}
                component={InputText}
            /> */}

      <div className="item">
        <div className="label">Role</div>
        <InputSelect
          options={listRole}
          onChange={(e) => setRole(e)}
          onTouched={() => false}
          value={role}
          name=""
        />
      </div>

      <div>
        <Button
          // className="mb20"
          label="Update"
          // onClick={props.onClick}
          isMiddle
          buttonType="success"
          className="btnBan mt20"
          type="submit"
        />
      </div>
    </form>
  );
};
