import React, { useState, useEffect, FC, memo } from "react";
import XLSX from "xlsx";

import { moduleConfig } from "../../../modules/module.config";
import { withReportWraper } from "../wraper";
import { AdminService } from "../../../services/admin";
import { InputDateTimeRange } from "../../../components/table-filter-inputs";
import {
    Message,
    Table,
    Button,
    ObjectUtils,
    CreateAlert,
    ITableStructureItem,
    NumberUtils,
} from "../../../modules";
import { getLocaleKey } from "../../../languages";
import { Icon, InputSelect } from "../../../components";
import moment from "moment";
import { getEnv } from "../../../configs";

export const ReportSystem = withReportWraper(
    memo(
        () => {
            const [rangeTime, setRangeTime] = useState({
                startTime: moment().startOf("month"), //Date.now() - 1000 * 60 * 60 * 24 * 30,
                endTime: moment().endOf("day"), //Date.now(),
            } as any);

            const [reports, setReports] = useState(null as any);
            const [systemReportDetail, setSystemReportDetail] = useState(
                null as any
            );
            const [modeOption, setModeOption] = useState("");

            useEffect(() => {
                if (!rangeTime) {
                    setReports(null);
                } else {
                    setReports(null);

                    AdminService.getSytemReport({
                        fromDate: new Date(rangeTime.startTime),
                        toDate: new Date(rangeTime.endTime),
                    })
                        .then((res) => {
                            const sumReports = Object.keys(res[0]).reduce(
                                (ouput: any, key: string) => {
                                    //if (typeof res[0][key] === 'number') ouput[key] = res.reduce((sum: number, item: any) => sum + item[key], 0);
                                    ouput[key] = res.reduce(
                                        (sum: number, item: any) =>
                                            sum + item[key],
                                        0
                                    );
                                    return ouput;
                                },
                                {}
                            );

                            setReports({
                                count: res.length,
                                data: res.sort(
                                    (a: any, b: any) => b.start - a.start
                                ),
                                sumReports,
                            });
                        })
                        .catch((err) => setReports({ error: err.error }));
                }
            }, [rangeTime]);

            const ModeOptions = [
                { label: "Balance", value: "userBalance" },
                // { label: "Hot Wallet", value: "hotWallet" },
                // { label: "Exchange", value: "exchange" },
                // { label: "Convert", value: "convert" },
                { label: "Order", value: "order" },
            ];

            const sumMiddleware =
                (func: (item: any) => any) =>
                (item: any, fetchData: any, column: ITableStructureItem) => {
                    if (item.isSum) {
                        if (column.key === "date") return "SUM";
                        if (column.key === "userCount")
                            return reports?.data[0]?.userCount;
                      if (column.key === "userBalanceUsdt" || column.key === "userBalanceTicket" || column.key === "userBalanceMet"
                        // || column.key === "userBalanceBonus"
                      )
                            return NumberUtils.toFormatNumber(
                                reports?.data[0]?.[column.key],
                                +getEnv("NUMBER_DECIMAL_DISPLAY")
                            );
                        // if (column.key === "hotWalletUsdt") return NumberUtils.toFormatNumber(reports?.data[0]?.hotWalletUsdt, 4);
                        // if (column.key === "hotWalletGp2")
                        //     return NumberUtils.toFormatNumber(
                        //         reports?.data[0]?.hotWalletGp2,
                        //         +getEnv("NUMBER_DECIMAL_DISPLAY")
                        //     );
                        if (
                            column.key &&
                            reports &&
                            reports.sumReports &&
                            typeof reports.sumReports[column.key] === "number"
                        ) {
                            if (Number.isNaN(reports.sumReports[column.key]))
                                return "--";
                            const value = NumberUtils.toFormatNumber(
                                +reports.sumReports[column.key] || 0,
                                +getEnv("NUMBER_DECIMAL_DISPLAY")
                            );
                            if (
                                [
                                    "userCount",
                                    "userOrderCount",
                                    "orderCount",
                                    "loginCount",
                                ].includes(column.key)
                            )
                                return `${(
                                    +reports.sumReports[column.key] || 0
                                ).toLocaleString(getLocaleKey(), {
                                    maximumFractionDigits: 0,
                                    minimumFractionDigits: 0,
                                })}`;
                            return `${value}`;
                        }

                        return "--";
                    }

                    return func(item);
                };

            const structure = [
                {
                    name: "Date",
                    key: "date",
                    render: sumMiddleware((item) => {
                        return (
                            <div>
                                {new Date(item.start).toLocaleDateString(
                                    getLocaleKey(),
                                    { hour12: false }
                                )}
                            </div>
                        );
                    }),
                    sort: {
                        key: "date",
                    },
                },
                {
                    name: "Total Volume",
                    key: "orderVolume",
                    render: sumMiddleware((item: any) => {
                        return NumberUtils.toFormatNumber(
                            +item?.orderVolume,
                            +getEnv("NUMBER_DECIMAL_DISPLAY")
                        );
                    }),
                    sort: {
                        key: "orderVolume",
                    },
                },
                {
                    name: "Users",
                    key: "userCount",
                    render: sumMiddleware((item: any) => {
                        return (+item.userCount).toLocaleString(
                            getLocaleKey(),
                            {
                                maximumFractionDigits: 0,
                                minimumFractionDigits: 0,
                            }
                        );
                    }),
                    sort: {
                        key: "userCount",
                    },
                },
                {
                    name: "Total User Orders",
                    key: "userOrderCount",
                    render: sumMiddleware((item: any) => {
                        return (+item.userOrderCount).toLocaleString(
                            getLocaleKey(),
                            {
                                maximumFractionDigits: 0,
                                minimumFractionDigits: 0,
                            }
                        );
                    }),
                    sort: {
                        key: "userOrderCount",
                    },
                },
                {
                    name: "Volume Profit",
                    key: "profitVolume",
                    className: "profit-volume",
                    render: sumMiddleware((item: any) => {
                        return (
                            <span
                                className={
                                    item?.profitVolume >= 0
                                        ? "profit-volume-green"
                                        : "profit-volume-red"
                                }
                            >
                                {NumberUtils.toFormatNumber(
                                    +item?.profitVolume,
                                    +getEnv("NUMBER_DECIMAL_DISPLAY")
                                )}
                            </span>
                        );
                    }),
                    sort: {
                        key: "profitVolume",
                    },
                },
                {
                    name: "User Balance USDT",
                    key: "userBalanceUsdt",
                    render: sumMiddleware((item: any) => {
                        return NumberUtils.toFormatNumber(
                            +item?.userBalanceUsdt,
                            +getEnv("NUMBER_DECIMAL_DISPLAY")
                        );
                    }),
                    sort: {
                        key: "userBalanceUsdt",
                    },
                },
                {
                    name: "Deposit USDT",
                    key: "depositUsdt",
                    render: sumMiddleware((item: any) => {
                        return NumberUtils.toFormatNumber(
                            +item?.depositUsdt,
                            +getEnv("NUMBER_DECIMAL_DISPLAY")
                        );
                    }),
                    sort: {
                        key: "depositUsdt",
                    },
                },
                {
                    name: "Withdraw USDT",
                    key: "withdrawUsdt",
                    render: sumMiddleware((item: any) => {
                        return NumberUtils.toFormatNumber(
                            +item?.withdrawUsdt,
                            +getEnv("NUMBER_DECIMAL_DISPLAY")
                        );
                    }),
                    sort: {
                        key: "withdrawUsdt",
                    },
                },
                {
                    name: "Total Fee",
                    key: "totalFee",
                    render: sumMiddleware((item: any) => {
                        return NumberUtils.toFormatNumber(
                            +item?.totalFee,
                            +getEnv("NUMBER_DECIMAL_DISPLAY")
                        );
                    }),
                    sort: {
                        key: "totalFee",
                    },
                },
                {
                    name: "Total Ticket Bonus",
                    key: "totalTicketBouns",
                    render: sumMiddleware((item: any) => {
                        return (+item.totalTicketBouns).toLocaleString(
                            getLocaleKey(),
                            {
                                maximumFractionDigits: 0,
                                minimumFractionDigits: 0,
                            }
                        );
                    }),
                    sort: {
                        key: "totalTicketBouns",
                    },
                },
                {
                    name: "User Balance Ticket",
                    key: "userBalanceTicket",
                    render: sumMiddleware((item: any) => {
                        return (+item.userBalanceTicket).toLocaleString(
                            getLocaleKey(),
                            {
                                maximumFractionDigits: 0,
                                minimumFractionDigits: 0,
                            }
                        );
                    }),
                    sort: {
                        key: "userBalanceTicket",
                    },
                },
                {
                    name: "User Balance MET",
                    key: "userBalanceMet",
                    render: sumMiddleware((item: any) => {
                        return (+item.userBalanceMet).toLocaleString(
                            getLocaleKey(),
                            {
                                maximumFractionDigits: 0,
                                minimumFractionDigits: 0,
                            }
                        );
                    }),
                    sort: {
                        key: "userBalanceMet",
                    },
                },
                // {
                //     name: "User Balance Bonus",
                //     key: "userBalanceBonus",
                //     render: sumMiddleware((item: any) => {
                //         return NumberUtils.toFormatNumber(
                //             +item?.userBalanceBonus,
                //             +getEnv("NUMBER_DECIMAL_DISPLAY")
                //         );
                //     }),
                //     sort: {
                //         key: "userBalanceBonus",
                //     },
                // },
                {
                    name: "Receive Daily Transfer",
                    key: "receiveDailyTransfer",
                    render: sumMiddleware((item: any) => {
                        return NumberUtils.toFormatNumber(
                            +item?.receiveDailyTransfer,
                            +getEnv("NUMBER_DECIMAL_DISPLAY")
                        );
                    }),
                    sort: {
                        key: "receiveDailyTransfer",
                    },
                },
                // {
                //     name: "Daily Transfer Bonus",
                //     key: "dailyTransferBonus",
                //     render: sumMiddleware((item: any) => {
                //         return NumberUtils.toFormatNumber(
                //             +item?.dailyTransferBonus,
                //             +getEnv("NUMBER_DECIMAL_DISPLAY")
                //         );
                //     }),
                //     sort: {
                //         key: "dailyTransferBonus",
                //     },
                // },
                {
                    name: "Daily Commission",
                    key: "dailyCommission",
                    render: sumMiddleware((item: any) => {
                        return NumberUtils.toFormatNumber(
                            +item?.dailyCommission,
                            +getEnv("NUMBER_DECIMAL_DISPLAY")
                        );
                    }),
                    sort: {
                        key: "dailyCommission",
                    },
                },
                // {
                //     name: "World Pool Commission 1",
                //     key: "worldPoolCommission1",
                //     render: sumMiddleware((item: any) => {
                //         return NumberUtils.toFormatNumber(
                //             +item?.worldPoolCommission1,
                //             +getEnv("NUMBER_DECIMAL_DISPLAY")
                //         );
                //     }),
                //     sort: {
                //         key: "worldPoolCommission1",
                //     },
                // },
                // {
                //     name: "World Pool Commission 2",
                //     key: "worldPoolCommission2",
                //     render: sumMiddleware((item: any) => {
                //         return NumberUtils.toFormatNumber(
                //             +item?.worldPoolCommission2,
                //             +getEnv("NUMBER_DECIMAL_DISPLAY")
                //         );
                //     }),
                //     sort: {
                //         key: "worldPoolCommission2",
                //     },
                // },
                // {
                //     name: "Hot Wallet USDT",
                //     key: "hotWalletUsdt",
                //     render: sumMiddleware((item: any) => {
                //         return NumberUtils.toFormatNumber(+item?.hotWalletUsdt, 4);
                //     }),
                //     sort: {
                //         key: "hotWalletUsdt",
                //     },
                // },
                // {
                //     name: "Withdraw USDT",
                //     key: "withdrawUsdt",
                //     render: sumMiddleware((item: any) => {
                //         return NumberUtils.toFormatNumber(+item?.withdrawUsdt, 4);
                //     }),
                //     sort: {
                //         key: "withdrawUsdt",
                //     },
                // },
                // {
                //     name: "Exchange In",
                //     key: "exchangeIn",
                //     render: sumMiddleware((item: any) => {
                //         return NumberUtils.toFormatNumber(
                //             +item?.exchangeIn,
                //             +getEnv("NUMBER_DECIMAL_DISPLAY")
                //         );
                //     }),
                //     sort: {
                //         key: "exchangeIn",
                //     },
                // },
                // {
                //     name: "Exchange Out Raw",
                //     key: "exchangeOutRaw",
                //     render: sumMiddleware((item: any) => {
                //         return NumberUtils.toFormatNumber(
                //             +item?.exchangeOutRaw,
                //             +getEnv("NUMBER_DECIMAL_DISPLAY")
                //         );
                //     }),
                //     sort: {
                //         key: "exchangeOutRaw",
                //     },
                // },
                // {
                //     name: "Exchange Out",
                //     key: "exchangeOut",
                //     render: sumMiddleware((item: any) => {
                //         return NumberUtils.toFormatNumber(
                //             +item?.exchangeOut,
                //             +getEnv("NUMBER_DECIMAL_DISPLAY")
                //         );
                //     }),
                //     sort: {
                //         key: "exchangeOut",
                //     },
                // },
                // {
                //     name: "Exchange Profit",
                //     key: "profitExchange",
                //     render: sumMiddleware((item: any) => {
                //         return NumberUtils.toFormatNumber(
                //             +item?.profitExchange,
                //             +getEnv("NUMBER_DECIMAL_DISPLAY")
                //         );
                //     }),
                //     sort: {
                //         key: "profitExchange",
                //     },
                // },
                // {
                //     name: "Exchange Fee 1%",
                //     key: "exchangeOutFeeTotal",
                //     render: sumMiddleware((item: any) => {
                //         return NumberUtils.toFormatNumber(
                //             +item?.exchangeOutFeeTotal,
                //             +getEnv("NUMBER_DECIMAL_DISPLAY")
                //         );
                //     }),
                //     sort: {
                //         key: "exchangeOutFeeTotal",
                //     },
                // },

                // {
                //     name: "Hot Wallet MTG",
                //     key: "hotWalletGp2",
                //     render: sumMiddleware((item: any) => {
                //         return NumberUtils.toFormatNumber(
                //             +item?.hotWalletGp2,
                //             +getEnv("NUMBER_DECIMAL_DISPLAY")
                //         );
                //     }),
                //     sort: {
                //         key: "hotWalletGp2",
                //     },
                // },
                // {
                //     name: "Refund MTG",
                //     key: "refundValue",
                //     render: sumMiddleware((item: any) => {
                //         return NumberUtils.toFormatNumber(
                //             +item?.refundValue,
                //             +getEnv("NUMBER_DECIMAL_DISPLAY")
                //         );
                //     }),
                //     sort: {
                //         key: "hotWalletGp2",
                //     },
                // },
                {
                    name: "Total Orders",
                    key: "orderCount",
                    render: sumMiddleware((item: any) => {
                        return (+item.orderCount).toLocaleString(
                            getLocaleKey(),
                            {
                                maximumFractionDigits: 0,
                                minimumFractionDigits: 0,
                            }
                        );
                    }),
                    sort: {
                        key: "orderCount",
                    },
                },
                {
                    name: "Login Count",
                    key: "loginCount",
                    render: sumMiddleware((item: any) => {
                        return (+item.loginCount).toLocaleString(
                            getLocaleKey(),
                            {
                                maximumFractionDigits: 0,
                                minimumFractionDigits: 0,
                            }
                        );
                    }),
                    sort: {
                        key: "loginCount",
                    },
                },
                // {
                //     name: "Rank Commission",
                //     key: "rankCommission",
                //     render: sumMiddleware((item: any) => {
                //         return NumberUtils.toFormatNumber(
                //             +item?.rankCommission,
                //             +getEnv("NUMBER_DECIMAL_DISPLAY")
                //         );
                //     }),
                //     sort: {
                //         key: "rankCommission",
                //     },
                // },
                // {
                //     name: "Cash Back 0.2%",
                //     key: "cashBack",
                //     render: sumMiddleware((item: any) => {
                //         return NumberUtils.toFormatNumber(
                //             +item?.cashBack,
                //             +getEnv("NUMBER_DECIMAL_DISPLAY")
                //         );
                //     }),
                //     sort: {
                //         key: "cashBack",
                //     },
                // },
                // {
                //     name: "MTG Converted",
                //     key: "convertRecieved",
                //     render: sumMiddleware((item: any) => {
                //         return NumberUtils.toFormatNumber(
                //             +item?.convertRecieved,
                //             +getEnv("NUMBER_DECIMAL_DISPLAY")
                //         );
                //     }),
                //     sort: {
                //         key: "convertRecieved",
                //     },
                // },
            ].filter((v) => {
                if (modeOption === "hotWallet")
                    return [
                        "date", 
                        // "hotWalletGp2", 
                        "hotWalletUsdt"
                    ].includes(
                        v.key
                    );
                if (modeOption === "userBalance")
                    return [
                        "date",
                        "userBalanceUsdt",
                        "userBalanceTicket",
                        "userBalanceMet",
                        // "userBalanceBonus"
                    ].includes(v.key);
                if (modeOption === "order")
                    return [
                        "date",
                        "orderVolume",
                        "orderCount",
                        "userOrderCount",
                        "profitVolume",
                        // "cashBack",
                        // "rankCommission",
                        // "refundValue",
                    ].includes(v.key);
                // if (modeOption === "exchange")
                //     return [
                //         "date",
                //         "exchangeIn",
                //         "exchangeOutRaw",
                //         "profitExchange",
                //         "exchangeOut",
                //         "exchangeOutFeeTotal",
                //     ].includes(v.key);
                if (modeOption === "convert")
                    return [
                        "date", 
                        // "convertRecieved"
                    ].includes(v.key);
                return true;
            });

            const handleExportExcel = async () => {
                return new Promise((resolve) => {
                    try {
                        const reportData = ObjectUtils.getIn(
                            reports,
                            "data",
                            []
                        );
                        const fileHead = structure.map((v) => v.name);
                        const data = reportData.map((item: any) =>
                            structure.map((column, index) => {
                                if (index === 0)
                                    return new Date(
                                        item["start"]
                                    ).toLocaleString(getLocaleKey());
                                if (
                                    [
                                        "orderVolume",
                                        "profitVolume",
                                        "userBalanceUsdt",
                                        "depositUsdt",
                                        "withdrawUsdt",
                                        "totalFee",
                                        // "userBalanceBonus",
                                        "receiveDailyTransfer",
                                        // "dailyTransferBonus",
                                        "dailyCommission",
                                        // "worldPoolCommission1",
                                        // "worldPoolCommission2"
                                        // "hotWalletGp2",
                                        // "refundValue",
                                        // "exchangeIn",
                                        // "exchangeOut",
                                        // "exchangeOutRaw",
                                        // "exchangeOutFeeTotal",
                                        // "profitExchange",
                                        // "rankCommission",
                                        // "cashBack",
                                        // "convertRecieved",
                                    ].includes(column?.key)
                                )
                                    return item[column.key]
                                        ? NumberUtils.toFormatNumber(
                                            +item[column.key],
                                            +getEnv("NUMBER_DECIMAL_DISPLAY")
                                        )
                                        : `0.00000000`;
                                return item[column.key] || 0;
                            })
                        );

                        const dataExport = [
                            fileHead,
                            ...data,
                            structure.map((column, index) => {
                                if (index === 0) return "SUM";
                                if (column.key === "date") return "SUM";
                                if (column.key === "userCount" || column.key === "userBalanceTicket" || column.key === "userBalanceMet")
                                    return reports?.data[0]?.[column.key];
                                if (
                                    [
                                        "userBalanceUsdt",
                                        // "userBalanceBonus",
                                        // "hotWalletGp2",
                                    ].includes(column?.key)
                                )
                                    return reports?.data[0][column.key]
                                        ? NumberUtils.toFormatNumber(
                                            +reports?.data[0][column.key],
                                            +getEnv("NUMBER_DECIMAL_DISPLAY")
                                        )
                                        : `0.00000000`;
                                if (
                                    [
                                        "orderVolume",
                                        "profitVolume",
                                        "depositUsdt",
                                        "withdrawUsdt",
                                        "totalFee",
                                        "receiveDailyTransfer",
                                        // "dailyTransferBonus",
                                        "dailyCommission",
                                        // "worldPoolCommission1",
                                        // "worldPoolCommission2"
                                        // "refundValue",
                                        // "exchangeIn",
                                        // "exchangeOut",
                                        // "exchangeOutRaw",
                                        // "exchangeOutFeeTotal",
                                        // "profitExchange",
                                        // "rankCommission",
                                        // "cashBack",
                                        // "convertRecieved",
                                    ].includes(column?.key)
                                )
                                    return reports.sumReports[column.key]
                                        ? NumberUtils.toFormatNumber(
                                            +reports.sumReports[column.key],
                                            +getEnv("NUMBER_DECIMAL_DISPLAY")
                                        )
                                        : `0.00000000`;
                                return reports.sumReports[column.key] || "0";
                            }),
                        ];

                        const ws = XLSX.utils.aoa_to_sheet(dataExport);
                        const wb = XLSX.utils.book_new();
                        XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

                        const now = new Date();
                        XLSX.writeFile(
                            wb,
                            `System report ${now
                                .toLocaleDateString()
                                .replace(/\//g, "-")} ${now
                                .toLocaleTimeString()
                                .replace(/:/g, "-")}.xlsx`,
                            { type: "binary" }
                        );

                        resolve(
                            CreateAlert({
                                type: "success",
                                message: "Export data success.",
                            })
                        );
                    } catch (error:any) {
                        resolve(
                            CreateAlert({
                                type: "danger",
                                message: error.message,
                            })
                        );
                    }
                });
            };

            return (
                <section className="box">
                    <div className="title">System report</div>

                    <SystemReportDetailPopup
                        detail={systemReportDetail}
                        onClose={() => setSystemReportDetail(null)}
                    />

                    <div className="SystemReport">
                        <div className="SystemReport__Head">
                            <div className="item">
                                <div className="label">Range time</div>
                                <InputDateTimeRange
                                    onChange={(e) => setRangeTime(e)}
                                    startTimeDefaultValue={rangeTime?.startTime}
                                    endTimeDefaultValue={rangeTime?.endTime}
                                    format={"MM-dd-y"}
                                />
                            </div>
                            <div className="item">
                                <div className="label">Mode</div>
                                <InputSelect
                                    options={ModeOptions}
                                    onChange={(e) => setModeOption(e)}
                                    onTouched={() => false}
                                    value={modeOption}
                                    name=""
                                />
                            </div>

                            <div className="item">
                                <div className="label">Export</div>
                                <Button
                                    label="Excel"
                                    buttonType="success"
                                    disabled={
                                        !reports ||
                                        (reports.data &&
                                            reports.data.length === 0)
                                    }
                                    onClick={handleExportExcel}
                                />
                            </div>
                        </div>

                        {(() => {
                            if (reports && !reports.data)
                                return (
                                    <Message
                                        type="emptyData"
                                        message={moduleConfig.translate(
                                            "no-data"
                                        )}
                                    />
                                );
                            if (!rangeTime)
                                return (
                                    <Message
                                        type="info"
                                        message="You have to select range time to export report"
                                    />
                                );
                            if (!reports) return <Message type="loading" />;
                            if (reports.error)
                                return (
                                    <Message type="error" {...reports.error} />
                                );

                            return (
                                <Table
                                    doubleScroll
                                    hasOrderColumn
                                    className="overload hideFooter"
                                    structure={structure}
                                    fetchData={async (params) => {
                                        let data = [...reports.data];

                                        if (params["date"] === "increase")
                                            data = data.sort(
                                                (a: any, b: any) =>
                                                    a.start - b.start
                                            );
                                        if (params["date"] === "descrease")
                                            data = data.sort(
                                                (a: any, b: any) =>
                                                    b.start - a.start
                                            );

                                        if (
                                            params["orderVolume"] === "increase"
                                        )
                                            data = data.sort(
                                                (a: any, b: any) =>
                                                    a.orderVolume -
                                                    b.orderVolume
                                            );
                                        if (
                                            params["orderVolume"] ===
                                            "descrease"
                                        )
                                            data = data.sort(
                                                (a: any, b: any) =>
                                                    b.orderVolume -
                                                    a.orderVolume
                                            );

                                        if (params["userCount"] === "increase")
                                            data = data.sort(
                                                (a: any, b: any) =>
                                                    a.userCount - b.userCount
                                            );
                                        if (params["userCount"] === "descrease")
                                            data = data.sort(
                                                (a: any, b: any) =>
                                                    b.userCount - a.userCount
                                            );

                                        if (
                                            params["userOrderCount"] ===
                                            "increase"
                                        )
                                            data = data.sort(
                                                (a: any, b: any) =>
                                                    a.userOrderCount -
                                                    b.userOrderCount
                                            );
                                        if (
                                            params["userOrderCount"] ===
                                            "descrease"
                                        )
                                            data = data.sort(
                                                (a: any, b: any) =>
                                                    b.userOrderCount -
                                                    a.userOrderCount
                                            );

                                        if (
                                            params["profitVolume"] ===
                                            "increase"
                                        )
                                            data = data.sort(
                                                (a: any, b: any) =>
                                                    a.profitVolume -
                                                    b.profitVolume
                                            );
                                        if (
                                            params["profitVolume"] ===
                                            "descrease"
                                        )
                                            data = data.sort(
                                                (a: any, b: any) =>
                                                    b.profitVolume -
                                                    a.profitVolume
                                            );

                                        if (
                                            params["userBalanceUsdt"] ===
                                            "increase"
                                        )
                                            data = data.sort(
                                                (a: any, b: any) =>
                                                    a.userBalanceUsdt -
                                                    b.userBalanceUsdt
                                            );
                                        if (
                                            params["userBalanceUsdt"] ===
                                            "descrease"
                                        )
                                            data = data.sort(
                                                (a: any, b: any) =>
                                                    b.userBalanceUsdt -
                                                    a.userBalanceUsdt
                                            );

                                        if (
                                            params["depositUsdt"] ===
                                            "increase"
                                        )
                                            data = data.sort(
                                                (a: any, b: any) =>
                                                    a.depositUsdt -
                                                    b.depositUsdt
                                            );
                                        if (
                                            params["depositUsdt"] ===
                                            "descrease"
                                        )
                                            data = data.sort(
                                                (a: any, b: any) =>
                                                    b.depositUsdt -
                                                    a.depositUsdt
                                            );

                                        if (
                                            params["withdrawUsdt"] ===
                                            "increase"
                                        )
                                            data = data.sort(
                                                (a: any, b: any) =>
                                                    a.withdrawUsdt -
                                                    b.withdrawUsdt
                                            );
                                        if (
                                            params["withdrawUsdt"] ===
                                            "descrease"
                                        )
                                            data = data.sort(
                                                (a: any, b: any) =>
                                                    b.withdrawUsdt -
                                                    a.withdrawUsdt
                                            );

                                        if (
                                            params["totalFee"] ===
                                            "increase"
                                        )
                                            data = data.sort(
                                                (a: any, b: any) =>
                                                    a.totalFee -
                                                    b.totalFee
                                            );
                                        if (
                                            params["totalFee"] ===
                                            "descrease"
                                        )
                                            data = data.sort(
                                                (a: any, b: any) =>
                                                    b.totalFee -
                                                    a.totalFee
                                            );

                                        if (
                                            params["totalTicketBouns"] ===
                                            "increase"
                                        )
                                            data = data.sort(
                                                (a: any, b: any) =>
                                                    a.totalTicketBouns -
                                                    b.totalTicketBouns
                                            );
                                        if (
                                            params["totalTicketBouns"] ===
                                            "descrease"
                                        )
                                            data = data.sort(
                                                (a: any, b: any) =>
                                                    b.totalTicketBouns -
                                                    a.totalTicketBouns
                                            );

                                        if (
                                            params["userBalanceTicket"] ===
                                            "increase"
                                        )
                                            data = data.sort(
                                                (a: any, b: any) =>
                                                    a.userBalanceTicket -
                                                    b.userBalanceTicket
                                            );
                                        if (
                                            params["userBalanceTicket"] ===
                                            "descrease"
                                        )
                                            data = data.sort(
                                                (a: any, b: any) =>
                                                    b.userBalanceTicket -
                                                    a.userBalanceTicket
                                            );

                                        if (
                                            params["userBalanceMet"] ===
                                            "increase"
                                        )
                                            data = data.sort(
                                                (a: any, b: any) =>
                                                    a.userBalanceMet -
                                                    b.userBalanceMet
                                            );
                                        if (
                                            params["userBalanceMet"] ===
                                            "descrease"
                                        )
                                            data = data.sort(
                                                (a: any, b: any) =>
                                                    b.userBalanceMet -
                                                    a.userBalanceMet
                                            );

                                        // if (
                                        //     params["userBalanceBonus"] ===
                                        //     "increase"
                                        // )
                                        //     data = data.sort(
                                        //         (a: any, b: any) =>
                                        //             a.userBalanceBonus -
                                        //             b.userBalanceBonus
                                        //     );
                                        // if (
                                        //     params["userBalanceBonus"] ===
                                        //     "descrease"
                                        // )
                                        //     data = data.sort(
                                        //         (a: any, b: any) =>
                                        //             b.userBalanceBonus -
                                        //             a.userBalanceBonus
                                        //     );

                                        if (
                                            params["receiveDailyTransfer"] ===
                                            "increase"
                                        )
                                            data = data.sort(
                                                (a: any, b: any) =>
                                                    a.receiveDailyTransfer -
                                                    b.receiveDailyTransfer
                                            );
                                        if (
                                            params["receiveDailyTransfer"] ===
                                            "descrease"
                                        )
                                            data = data.sort(
                                                (a: any, b: any) =>
                                                    b.receiveDailyTransfer -
                                                    a.receiveDailyTransfer
                                            );

                                        // if (
                                        //     params["dailyTransferBonus"] ===
                                        //     "increase"
                                        // )
                                        //     data = data.sort(
                                        //         (a: any, b: any) =>
                                        //             a.dailyTransferBonus -
                                        //             b.dailyTransferBonus
                                        //     );
                                        // if (
                                        //     params["dailyTransferBonus"] ===
                                        //     "descrease"
                                        // )
                                        //     data = data.sort(
                                        //         (a: any, b: any) =>
                                        //             b.dailyTransferBonus -
                                        //             a.dailyTransferBonus
                                        //     );

                                        if (
                                            params["dailyCommission"] ===
                                            "increase"
                                        )
                                            data = data.sort(
                                                (a: any, b: any) =>
                                                    a.dailyCommission -
                                                    b.dailyCommission
                                            );
                                        if (
                                            params["dailyCommission"] ===
                                            "descrease"
                                        )
                                            data = data.sort(
                                                (a: any, b: any) =>
                                                    b.dailyCommission -
                                                    a.dailyCommission
                                            );

                                        // if (
                                        //     params["worldPoolCommission1"] ===
                                        //     "increase"
                                        // )
                                        //     data = data.sort(
                                        //         (a: any, b: any) =>
                                        //             a.worldPoolCommission1 -
                                        //             b.worldPoolCommission1
                                        //     );
                                        // if (
                                        //     params["worldPoolCommission1"] ===
                                        //     "descrease"
                                        // )
                                        //     data = data.sort(
                                        //         (a: any, b: any) =>
                                        //             b.worldPoolCommission1 -
                                        //             a.worldPoolCommission1
                                        //     );

                                        // if (
                                        //     params["worldPoolCommission2"] ===
                                        //     "increase"
                                        // )
                                        //     data = data.sort(
                                        //         (a: any, b: any) =>
                                        //             a.worldPoolCommission2 -
                                        //             b.worldPoolCommission2
                                        //     );
                                        // if (
                                        //     params["worldPoolCommission2"] ===
                                        //     "descrease"
                                        // )
                                        //     data = data.sort(
                                        //         (a: any, b: any) =>
                                        //             b.worldPoolCommission2 -
                                        //             a.worldPoolCommission2
                                        //     );

                                        // if (params["exchangeIn"] === "increase")
                                        //     data = data.sort(
                                        //         (a: any, b: any) =>
                                        //             a.exchangeIn - b.exchangeIn
                                        //     );
                                        // if (
                                        //     params["exchangeIn"] === "descrease"
                                        // )
                                        //     data = data.sort(
                                        //         (a: any, b: any) =>
                                        //             b.exchangeIn - a.exchangeIn
                                        //     );
                                        // if (
                                        //     params["exchangeOutRaw"] ===
                                        //     "increase"
                                        // )
                                        //     data = data.sort(
                                        //         (a: any, b: any) =>
                                        //             a.exchangeOutRaw -
                                        //             b.exchangeOutRaw
                                        //     );
                                        // if (
                                        //     params["exchangeOutRaw"] ===
                                        //     "descrease"
                                        // )
                                        //     data = data.sort(
                                        //         (a: any, b: any) =>
                                        //             b.exchangeOutRaw -
                                        //             a.exchangeOutRaw
                                        //     );

                                        // if (
                                        //     params["profitExchange"] ===
                                        //     "increase"
                                        // )
                                        //     data = data.sort(
                                        //         (a: any, b: any) =>
                                        //             a.profitExchange -
                                        //             b.profitExchange
                                        //     );
                                        // if (
                                        //     params["profitExchange"] ===
                                        //     "descrease"
                                        // )
                                        //     data = data.sort(
                                        //         (a: any, b: any) =>
                                        //             b.profitExchange -
                                        //             a.profitExchange
                                        //     );

                                        // if (
                                        //     params["exchangeOut"] === "increase"
                                        // )
                                        //     data = data.sort(
                                        //         (a: any, b: any) =>
                                        //             a.exchangeOut -
                                        //             b.exchangeOut
                                        //     );
                                        // if (
                                        //     params["exchangeOutFeeTotal"] ===
                                        //     "descrease"
                                        // )
                                        //     data = data.sort(
                                        //         (a: any, b: any) =>
                                        //             b.exchangeOut -
                                        //             a.exchangeOut
                                        //     );

                                        // if (
                                        //     params["exchangeOutFeeTotal"] ===
                                        //     "increase"
                                        // )
                                        //     data = data.sort(
                                        //         (a: any, b: any) =>
                                        //             a.exchangeOutFeeTotal -
                                        //             b.exchangeOutFeeTotal
                                        //     );
                                        // if (
                                        //     params["exchangeOutFeeTotal"] ===
                                        //     "descrease"
                                        // )
                                        //     data = data.sort(
                                        //         (a: any, b: any) =>
                                        //             b.exchangeOutFeeTotal -
                                        //             a.exchangeOutFeeTotal
                                        //     );

                                        if (
                                            params["hotWalletUsdt"] ===
                                            "increase"
                                        )
                                            data = data.sort(
                                                (a: any, b: any) =>
                                                    a.hotWalletUsdt -
                                                    b.hotWalletUsdt
                                            );
                                        if (
                                            params["hotWalletUsdt"] ===
                                            "descrease"
                                        )
                                            data = data.sort(
                                                (a: any, b: any) =>
                                                    b.hotWalletUsdt -
                                                    a.hotWalletUsdt
                                            );

                                        // if (
                                        //     params["hotWalletGp2"] ===
                                        //     "increase"
                                        // )
                                        //     data = data.sort(
                                        //         (a: any, b: any) =>
                                        //             a.hotWalletGp2 -
                                        //             b.hotWalletGp2
                                        //     );
                                        // if (
                                        //     params["hotWalletGp2"] ===
                                        //     "descrease"
                                        // )
                                        //     data = data.sort(
                                        //         (a: any, b: any) =>
                                        //             b.hotWalletGp2 -
                                        //             a.hotWalletGp2
                                        //     );

                                        // if (
                                        //     params["refundValue"] === "increase"
                                        // )
                                        //     data = data.sort(
                                        //         (a: any, b: any) =>
                                        //             a.refundValue -
                                        //             b.refundValue
                                        //     );
                                        // if (
                                        //     params["refundValue"] ===
                                        //     "descrease"
                                        // )
                                        //     data = data.sort(
                                        //         (a: any, b: any) =>
                                        //             b.refundValue -
                                        //             a.refundValue
                                        //     );

                                        if (params["orderCount"] === "increase")
                                            data = data.sort(
                                                (a: any, b: any) =>
                                                    a.orderCount - b.orderCount
                                            );
                                        if (
                                            params["orderCount"] === "descrease"
                                        )
                                            data = data.sort(
                                                (a: any, b: any) =>
                                                    b.orderCount - a.orderCount
                                            );

                                        if (params["loginCount"] === "increase")
                                            data = data.sort(
                                                (a: any, b: any) =>
                                                    a.loginCount - b.loginCount
                                            );
                                        if (
                                            params["loginCount"] === "descrease"
                                        )
                                            data = data.sort(
                                                (a: any, b: any) =>
                                                    b.loginCount - a.loginCount
                                            );

                                        // if (
                                        //     params["rankCommission"] ===
                                        //     "increase"
                                        // )
                                        //     data = data.sort(
                                        //         (a: any, b: any) =>
                                        //             a.rankCommission -
                                        //             b.rankCommission
                                        //     );
                                        // if (
                                        //     params["rankCommission"] ===
                                        //     "descrease"
                                        // )
                                        //     data = data.sort(
                                        //         (a: any, b: any) =>
                                        //             b.rankCommission -
                                        //             a.rankCommission
                                        //     );

                                        // if (params["cashBack"] === "increase")
                                        //     data = data.sort(
                                        //         (a: any, b: any) =>
                                        //             a.cashBack - b.cashBack
                                        //     );
                                        // if (params["cashBack"] === "descrease")
                                        //     data = data.sort(
                                        //         (a: any, b: any) =>
                                        //             b.cashBack - a.cashBack
                                        //     );

                                        // if (
                                        //     params["convertRecieved"] ===
                                        //     "increase"
                                        // )
                                        //     data = data.sort(
                                        //         (a: any, b: any) =>
                                        //             a.convertRecieved -
                                        //             b.convertRecieved
                                        //     );
                                        // if (
                                        //     params["convertRecieved"] ===
                                        //     "descrease"
                                        // )
                                        //     data = data.sort(
                                        //         (a: any, b: any) =>
                                        //             b.convertRecieved -
                                        //             a.convertRecieved
                                        //     );

                                        return {
                                            data: [{ isSum: true }, ...data],
                                            count: data.length,
                                        };
                                    }}
                                />
                            );
                        })()}
                    </div>
                </section>
            );
        },
        () => true
    )
);

export const SystemReportDetailPopup: FC<{
    detail: any;
    onClose: () => void;
}> = (props) => {
    if (!props.detail) return null;

    const item = props.detail;

    const data = [
        {
            label: "Start",
            value: `${new Date(item.start).toLocaleDateString(getLocaleKey(), {
                hour12: false,
            })} - ${new Date(item.start).toLocaleTimeString(getLocaleKey(), {
                hour12: false,
            })}`,
        },
        {
            label: "End",
            value: `${new Date(item.end).toLocaleDateString(getLocaleKey(), {
                hour12: false,
            })} - ${new Date(item.start).toLocaleTimeString(getLocaleKey(), {
                hour12: false,
            })}`,
        },
        {
            label: "Modified",
            value: `${new Date(item.modified).toLocaleDateString(
                getLocaleKey(),
                { hour12: false }
            )} - ${new Date(item.start).toLocaleTimeString(getLocaleKey(), {
                hour12: false,
            })}`,
        },
        {
            label: "Total won/lose volume",
            value: `${(+item.wonVolume).toLocaleString(getLocaleKey(), {
                maximumFractionDigits: 2,
            })}/${(+item.lostVolume).toLocaleString(getLocaleKey(), {
                maximumFractionDigits: 2,
            })}`,
        },
        {
            label: "Hot Wallet",
            value: (
                <span>
                    MTG:{" "}
                    {(+item.hotWalletGp2).toLocaleString(getLocaleKey(), {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                    })}{" "}
                    <br />
                    USDT:{" "}
                    {(+item.hotWalletUsdt).toLocaleString(getLocaleKey(), {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                    })}
                </span>
            ),
        },
        // {
        //     label: 'Deposit', value: <span>
        //         ETH: {(+item.depositEth).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2, minimumFractionDigits: 2 })} <br />
        //         USDT: {(+item.depositUsdt).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
        //     </span>
        // },
        {
            label: "Withdraw",
            value: (
                <span>
                    MTG:{" "}
                    {(+item.withdrawGp2).toLocaleString(getLocaleKey(), {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                    })}{" "}
                    <br />
                    USDT:{" "}
                    {(+item.withdrawUsdt).toLocaleString(getLocaleKey(), {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                    })}
                </span>
            ),
        },
        {
            label: "User 's Balance",
            value: (
                <span>
                    MTG:{" "}
                    {(+item.userBalanceGp2).toLocaleString(getLocaleKey(), {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                    })}{" "}
                    <br />
                    {/* USD: {(+item.userBalanceUsd).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2, minimumFractionDigits: 2 })} <br /> */}
                    USDT:{" "}
                    {(+item.userBalanceUsdt).toLocaleString(getLocaleKey(), {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                    })}{" "}
                    <br />
                    {/* BUT: {(+item.userBalanceBut).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2, minimumFractionDigits: 2 })} */}
                </span>
            ),
        },
        {
            label: "Safe",
            value: (
                <span>
                    MTG:{" "}
                    {(+item.safeGp2).toLocaleString(getLocaleKey(), {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                    })}{" "}
                    <br />
                    USDT:{" "}
                    {(+item.safeUsdt).toLocaleString(getLocaleKey(), {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                    })}
                </span>
            ),
        },
        {
            label: "Login Count",
            key: "loginCount",
            value: item.loginCount,
        },
        {
            label: "Direct Commission",
            key: "directCommission",
            value: item.directCommission,
        },
        {
            label: "CashBack",
            key: "cashBack",
            value: (+item.cashBack).toLocaleString(getLocaleKey(), {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            }),
        },
        {
            label: "New Package",
            key: "newPackage",
            value: item.newPackage,
        },
        {
            label: "Trading Commission",
            key: "rankCommission",
            value: item.rankCommission,
        },
    ];

    return (
        <div
            className="SystemReport__DetailPopup"
            id="SystemReport__DetailPopup"
            onClick={(e: any) =>
                e.target.id === "SystemReport__DetailPopup"
                    ? props.onClose()
                    : null
            }
        >
            <div className="box">
                <div className="boxTitle">
                    <span>Detail Report</span>
                    <div className="btnClose" onClick={() => props.onClose()}>
                        <Icon.Close />
                    </div>
                </div>
                <div className="content">
                    <table>
                        <tbody>
                            {data.map((row, key) => (
                                <tr key={key}>
                                    <td>{row.label}</td>
                                    <td>{row.value}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};
